import React , {useState} from 'react';


import {Link} from 'react-router-dom';
import img1 from '../../assets/images/icon/googleplay.png'
import img2 from '../../assets/images/icon/appstore.png'
import img3 from '../../assets/images/blog/teams.png'


function Download01(props) {

    const [dataBlock] = useState(
        {
            heading: 'With Cryptor Trade, you can be sure your trading skills are matched',
            desc : 'At Angel Grace Time, we believe in growing together. Our *Referral Bonus* and *Team Build Reward* system allows you to earn extra income by inviting others to join the platform. The more you refer, the more you earn!',
        }
    );

    const [dataList] = useState([
        {
            title: 'Refferal Bonus',
            text: 'Referrals Investment Your Bonus Percentage',
            paragraph:'5 Rs. to 25 Rs. - 7%',
           
            paragraph1:'30 Rs. to 55 Rs. - 10%',
           
            paragraph2:'60 Rs. to 100 Rs. - 12%',
           
            content: 'Earn a percentage of every new members investment when you refer them to the platform. Your referral bonus will depend on the amount your referral invests.'
        },
        // {
        //     title: 'Take Control Of Your Wealth',
        //     text: 'Earn a percentage of every new members investment when you refer them to the platform. Your referral bonus will depend on the amount your referral invests.'
        // },
    ])

    return (
        <section className="download">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div
                    className="download__content"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading">{dataBlock.heading}</h3>
                    <p className="fs-20 decs">
                        {dataBlock.desc}
                    </p>
                    <ul className="list">

                        {
                            dataList.map((data,idx) =>(
                                <li key={idx}>
                                    <h6 className="title">
                                        <span className="icon-check"></span>{data.title}
                                    </h6>
                                    <p className="text">
                                        {data.text}
                                    </p>
                                    <p className="text">
                                        {data.paragraph}
                                    </p>
                                    
                                    <p className="text">
                                        {data.paragraph1}
                                    </p>

                                    <p className="text">
                                        {data.paragraph2}
                                    </p>
                                   
                                    <p className="text">
                                        {data.content}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                    {/* <div className="group-button">
                        <Link to="#"
                        ><img src={img1} alt="Rockie"
                        /></Link>
                        <Link to="#"
                        ><img src={img2} alt="Rockie"
                        /></Link>
                    </div> */}
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="download__image">
                    {/* <div className="button">Scan To Download</div> */}
                    <img src={img3} alt="Rockie" />
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Download01;