import React , {useState} from 'react';


import {Link} from 'react-router-dom';
import img1 from '../../assets/images/icon/googleplay.png'
import img2 from '../../assets/images/icon/appstore.png'
import img3 from '../../assets/images/blog/plans.png'


function Download02(props) {

    const [dataBlock] = useState(
        {
            heading: 'Our Investment Plans',
            desc : '15 Days plan',
            desc1 : '2%',
            desc2 : 'Example*: Invest 100 Rs. for 1 month and receive 110 Rs. (10 Rs. profit + 100 Rs. principal).',
        },
        
    );

    const [dataList] = useState([
        {
            title: '15 days plan',
            text: '2%',
         
           
            // paragraph:'Example*: Invest 100 Rs. for 1 month and receive 110 Rs. (10 Rs. profit + 100 Rs. principal).',
           
          
        },
        {
            title: '30 days plan',
            text: '5%',
         
            // paragraph:'Example*: Invest 100 Rs. for 3 months and receive 112.5 Rs. monthly. After 3 months, you will have earned 37.5 Rs. in profit, totaling 137.5 Rs.. 6 month pla',
           
        
           
          
        },
        {
            title: '45 days plan',
            text: '10%',
         
        
            // paragraph:'*Example*: Invest 100 Rs. for 6 months and receive 115 Rs. monthly. After 6 months, you will have earned 90 Rs. in profit, totaling 190 Rs..',
           
          
        },
        {
            title: '60 days plan',
            text: '15%',
         
            // paragraph:'Example*: Invest 100 Rs. for 12 months and receive 117.5 Rs. monthly. Over 12 months, you will earn a total of 210 Rs. in profit,totaling 310 Rs..',
          
          
        },
        {
            title: '90 days plan',
            text: '20%',
         
          
           
            // paragraph:'Example*: Invest 100 Rs. for 18 months and receive 120 Rs. monthly. After 18 months, you will have earned 360 Rs. in profit, totaling 460 Rs..',
           
          
        },
        {
            title: '120 days plan',
            text: '30%',
         
          
           
            // paragraph:'Example*: Invest 100 Rs. for 18 months and receive 120 Rs. monthly. After 18 months, you will have earned 360 Rs. in profit, totaling 460 Rs..',
           
          
        },
        {
            title: '180 days plan',
            text: '46%',
         
          
           
            // paragraph:'Example*: Invest 100 Rs. for 18 months and receive 120 Rs. monthly. After 18 months, you will have earned 360 Rs. in profit, totaling 460 Rs..',
           
          
        },
        {
            title: '365 days plan',
            text: '99%',
         
          
           
            // paragraph:'Example*: Invest 100 Rs. for 18 months and receive 120 Rs. monthly. After 18 months, you will have earned 360 Rs. in profit, totaling 460 Rs..',
           
          
        },
        // {
        //     title: 'Take Control Of Your Wealth',
        //     text: 'Earn a percentage of every new members investment when you refer them to the platform. Your referral bonus will depend on the amount your referral invests.'
        // },
    ])

    return (
        <section className="download">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div
                    className="download__content"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading">{dataBlock.heading}</h3>
                    {/* <p className="fs-20 decs">
                        {dataBlock.desc}
                    </p> */}
                   
               
                    <ul className="list">

                        {
                            dataList.map((data,idx) =>(
                                <li key={idx}>
                                    <h6 className="title">
                                        <span className="icon-check"></span>{data.title}
                                    </h6>
                                    <p className="text">
                                        {data.text}
                                    </p>
                                    <p className="text">
                                        {data.paragraph}
                                    </p>
                                    
                                  
                                  
                                  

                                 
                                </li>
                            ))
                        }
                    </ul>
                    {/* <div className="group-button">
                        <Link to="#"
                        ><img src={img1} alt="Rockie"
                        /></Link>
                        <Link to="#"
                        ><img src={img2} alt="Rockie"
                        /></Link>
                    </div> */}
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="download__image">
                    {/* <div className="button">Scan To Download</div> */}
                    <img src={img3} alt="Rockie" />
                    </div>
                    <br></br>
                    <br></br>
                    <div className="download__image">
                    <h6 className="title">Investor can invest fm,
(5$ minimum to 100$
maximum)</h6>
                   
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Download02;