import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import video1 from '../../../public/newVideo.mp4'; // Replace with your actual video path

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Button from '../button';

Banner01.propTypes = {
    data: PropTypes.array,
};

function Banner01(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            title: 'Welcome to Our Platform',
            desc: 'At Angel Grace Time, we blend cutting-edge technology with divine wisdom to create a unique trading platform that benefits our community with consistent profits. Our approach is not only driven by market analysis but also guided by grace and the right timing, ensuring that every step we take is aligned with the best opportunities for growth and success.',
            title2: 'Our Partners'
        }
    );
    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                            <h2 className="title">{dataBlock.title}</h2>
                            <p className="fs-20 desc">
                                {dataBlock.desc}
                            </p>
                            <Button title='CONTINUE' path='#' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                          <video
    src="/newVideo2.mp4"  // This directly references the video from the public folder
    autoPlay
    muted
    loop
    style={{ height: "400px", width: "700px", marginTop: "20px" }}
/>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner01;
