

import img1 from '../images/coin/btc.png';
import img2 from '../images/coin/eth.png';
import img3 from '../images/coin/bnb.png';
import img4 from '../images/coin/tet.png';
import img5 from '../images/coin/sol.png';
import img6 from '../images/coin/ada.png';
import img7 from '../images/coin/avax.png';

import chart1 from '../images/icon/chart-up.png';
import chart2 from '../images/icon/chart-down.png';
const dataCoin = [
    {
        id: 1,
        icon: img1,
        name: '30 Rs.',
        uint: 'BTC',
        price: '33 Rs.',
        sale: '41.25 Rs.',
        cap: '57 Rs.',
          price1: '93 Rs. ',
          price2: '138 Rs. ',
        chart: chart1,
        // class: '138 Rs.',
        
    },
    {
        id: 2,
        icon: img2,
        name: '40 Rs.',
        uint: 'BTC',
        price: '44 Rs.',
        sale: '55 Rs.',
        cap: '76 Rs.',
          price1: '124 Rs.',
          price2: '184 Rs. ',
        chart: chart1,
        // class: '138 Rs.',
        
    },
    {
        id: 3,
        icon: img3,
        name: '50 Rs.',
        uint: 'BTC',
        price: '55 Rs.',
        sale: '68.75 Rs.',
        cap: '95 Rs.',
          price1: '155 Rs.',
          price2: '230 Rs.',
        chart: chart1,
        // class: '138 Rs.',
        
    },
    {
        id: 4,
        icon: img4,
        name: '75 Rs.',
        uint: 'BTC',
        price: '82.50 Rs.',
        sale: '103.1 Rs.',
        cap: '142.5 Rs.',
          price1: '232.5 Rs.',
          price2: '345 Rs.',
        chart: chart1,
        // class: '138 Rs.',
        
    },
    {
        id: 5,
        icon: img5,
        name: '100 Rs.',
        uint: 'BTC',
        price: '110 Rs.',
        sale: '137.5 Rs.',
        cap: '190 Rs.',
          price1: '310 Rs.',
          price2: '460 Rs.',
        chart: chart1,
        // class: '138 Rs.',
        
    },
    // {
    //     id: 6,
    //     icon: img1,
    //     name: 'XRP',
    //     uint: 'XRP',
    //     price: ' Rs.56,623.54',
    //     sale: '-2.22%',
    //     cap: ' Rs.880,423,640,582',
    //     chart: chart2,
    //     class: 'down',
        
    // },
    // {
    //     id: 7,
    //     icon: img6,
    //     name: 'XRP',
    //     uint: 'XRP',
    //     price: ' Rs.56,623.54',
    //     sale: '-2.22%',
    //     cap: ' Rs.880,423,640,582',
    //     chart: chart1,
    //     class: 'up',
        
    // },
    // {
    //     id: 8,
    //     icon: img7,
    //     name: 'Cardano',
    //     uint: 'ADA',
    //     price: ' Rs.56,623.54',
    //     sale: '+0.8%',
    //     cap: ' Rs.880,423,640,582',
    //     chart: chart1,
    //     class: 'up',
        
    // },
   
]

export default dataCoin;