

import img1 from '../images/icon/Cloud.png';
import img2 from '../images/icon/Wallet.png';
import img3 from '../images/icon/Mining.png';
import img4 from '../images/icon/Comparison.png';

const dataWork = [
    {
        id: 1,
        img: img1,
        step: '1',
        title: 'Expert Driven Investment',
        // text: 'Stacks is a production-ready library of stackable content blocks built in React Native.'
    },
    {
        id: 2,
        img: img2,
        step: '2',
        title: 'High Profit Markets',
        // text: 'Stacks is a production-ready library of stackable content blocks built in React Native.'
    },
    {
        id: 3,
        img: img3,
        step: '3',
        title: 'Community Profit Sharing',
        // text: 'Stacks is a production-ready library of stackable content blocks built in React Native.'
    },
    {
        id: 4,
        img: img4,
        step: '4',
        title: 'Divine Timing And Grace',
        // text: 'Stacks is a production-ready library of stackable content blocks built in React Native.'
    },
   
]

export default dataWork;