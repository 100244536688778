import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import img1 from '../../assets/images/coin/btc.png'
import img2 from '../../assets/images/coin/eth.png'
import img3 from '../../assets/images/coin/tet.png'
import img4 from '../../assets/images/coin/bnb.png'

import './styles.scss';

import {Link} from 'react-router-dom';

Crypto01.propTypes = {
    
};

function Crypto01(props) {

    const [dataCrytoTab] = useState([
        {
            id: 1,
            title: 'Our Investment Plans',
        },
        // {
        //     id: 2,
        //     title: 'DeFi',
        // },
        // {
        //     id: 3,
        //     title: 'BSC',
        // },
        // {
        //     id: 4,
        //     title: 'NFT',
        // },
        // {
        //     id: 5,
        //     title: 'Metaverse',
        // },
        // {
        //     id: 6,
        //     title: 'Polkadot',
        // },
        // {
        //     id: 7,
        //     title: 'Solana',
        // },
        // {
        //     id: 8,
        //     title: 'Opensea',
        // },
        // {
        //     id: 9,
        //     title: 'Makersplace',
        // },
    ])
    const [dataCrytoContent] = useState([
        // {
        //     id: 1,
        //     active: '',
        //     icon: img1,
        //     name: 'INVESTMENT',
        //     unit: 'BTC/USD',
        //     price: '5$',
        //     price1: '10$',
        //     price2: '15$',
        //     price3: '20$',
        //     price4: '25$',
           

           
           
           

        //     pricesale: '36,641.20',
        //     sale:'-0.79%',
        //     class: 'critical'
        // },
        {
            id: 2,
            active: 'active',
            icon: img2,
            name: '15 days',
            price: '2%',
           
        },
        {
            id: 3,
            icon: img3,
            name: '30 days',
            price: '5%',
           
        },
        {
            id: 4,
            icon: img4,
            name: '45 days',
            price: '10%',
         
        },
        {
            id: 5,
            icon: img4,
            name: '60 days',
            price: '15%',
          
        },
        {
            id: 6,
            icon: img4,
            name: '90 days',
            price: '20%',
         
        },
        {
            id: 7,
            icon: img4,
            name: '120 days',
            price: '30%',
         
        },
        {
            id: 8,
            icon: img4,
            name: '180 days',
            price: '46%',
         
        },
        {
            id: 9,
            icon: img4,
            name: '365 days',
            price: '99%',
         
        },
     
    ])
    
    return (
        <section className="crypto" data-aos="fade-up" data-aos-duration="1000">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="crypto__main">
                        <Tabs>
                            <TabList>
                                {
                                    dataCrytoTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            {
                                dataCrytoTab.map(data => (
                                    <TabPanel key={data.id}>
                                        <div className="content-inner">
                                            {
                                                dataCrytoContent.map(data => (
                                                    <div key={data.id} className={`crypto-box ${data.active}`}>
                                                        <div className="top">
                                                            <Link to="#">
                                                            <img src={data.icon} alt="rockie" />
                                                            <span>{data.name}</span>
                                                            <span className="unit">{data.unit}</span></Link>
                                                        </div>
                                                        {/* <h6 className="price">{data.price}</h6>
                                                        <h6 className="price1">{data.price1}</h6>
                                                        <h6 className="price2">{data.price2}</h6>
                                                        <h6 className="price3">{data.price3}</h6>
                                                        <h6 className="price4">{data.price4}</h6> */}
                                                        <div className="bottom">
                                                            <p>{data.price}</p>
                                                            {/* <p className={`sale ${data.class}`}>{data.sale}</p> */}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        
                                        </div>
                                    </TabPanel>
                                ))
                            }
                            

                        </Tabs> 
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Crypto01;